import classNames from "classnames";
import React from "react";
import { Container, Icon, Paragraph, Tabs } from "shared/components";
import { TabsProvider } from "shared/components/Tabs/Tabs.Provider";

import { SliderItem } from "../SliderItem";
import styles from "./SliderView.module.scss";

export const SliderView = ({
  items,
  title,
  description,
  extractedTabs,
  tabsEnabled,
}) => {
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  return (
    <>
      <Container className={styles.header}>
        <div className={styles.top}>
          <div className={styles["top-content"]}>
            <h2 className={styles.title}>{title}</h2>
            <Paragraph color="grey" className={styles.description}>
              {description}
            </Paragraph>
          </div>
        </div>
      </Container>
      <TabsProvider items={extractedTabs} defaultTab tabsEnabled={tabsEnabled}>
        <Container className={styles.header}>
          <div className={styles["top-navigation"]}>
            <Tabs />
            <div className={styles["navigation-wrapper"]}>
              <div className={styles.pagination}></div>
              <div className={styles["slider-navigation"]}>
                <div
                  ref={prevRef}
                  className={classNames(
                    styles.navigation,
                    styles["navigation-prev"]
                  )}
                >
                  <Icon className={styles.icon} name="arrow_slider_prev" />
                </div>
                <div
                  ref={nextRef}
                  className={classNames(
                    styles.navigation,
                    styles["navigation-next"]
                  )}
                >
                  <Icon className={styles.icon} name="arrow_slider_next" />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <SliderItem
          items={items}
          nextRef={nextRef}
          prevRef={prevRef}
          paginationWrapper={styles.pagination}
        />
      </TabsProvider>
    </>
  );
};

export default SliderView;
