import classNames from "classnames";
import { useMemo } from "react";
import { SwiperLayout } from "shared/components";
import { useTabsState } from "shared/components/Tabs/Tabs.hooks";
import { SwiperSlide } from "swiper/react";

import { Item } from "../Item";
import styles from "./SliderItem.module.scss";

export const SliderItem = ({
  items,
  prevRef,
  nextRef,
  pagination,
  paginationWrapper,
}) => {
  const { activeTab, isDefaultTab, tabsEnabled } = useTabsState();

  const filterItems = useMemo(() => {
    if (isDefaultTab || !tabsEnabled) {
      return items;
    }

    return items.filter(
      ({ tag }) => tag.toLowerCase() === activeTab.toLowerCase()
    );
  }, [isDefaultTab, tabsEnabled, items, activeTab]);

  return (
    <div className={styles.slider}>
      <SwiperLayout
        paginationWrapper={paginationWrapper}
        slidesPerView={3}
        prevRef={prevRef}
        nextRef={nextRef}
      >
        {filterItems.map((item) => (
          <SwiperSlide key={item.id} className={styles.wrapper}>
            <Item className={classNames(styles.item, styles.slide)} {...item} />
          </SwiperSlide>
        ))}
      </SwiperLayout>
    </div>
  );
};
